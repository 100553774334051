import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/edgers/homeowner-edgers/',
    imageId: 'homeownerEdgerImage',
    name: 'Homeowner Edgers',
    alt: 'STIHL Homeowner Edgers',
  },
  {
    link: '/stihl/edgers/professional-edgers/',
    imageId: 'professionalEdgerImage',
    name: 'Professional Edgers',
    alt: 'STIHL Professional Edgers',
  },
  {
    link: '/stihl/edgers/battery-edgers/',
    imageId: 'batteryEdgerImage',
    name: 'Battery Edgers',
    alt: 'STIHL Battery Edgers',
  },
]

const EdgersPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Edgers | Hutson Inc</title>
        <meta
          name='description'
          content='Take control of your sidewalks, walkways and curbs with a STIHL edger. Built with adjustable depth wheels, ergonomic handles, and hardened steel blades, STIHL edgers make the work easy. Learn more about STIHL edgers at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Edgers',
                'item': 'https://www.hutsoninc.com/stihl/edgers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Edgers' />

      <Content>
        <Floater items={floaterItems} />

        <Promos data={promos} type='STIHL Edger' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query edgersStihlQuery($category: String = "edgers") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/edgers/edgers-bg.jpg" }) {
      ...FullWidthImage
    }
    homeownerEdgerImage: file(relativePath: { eq: "stihl/edgers/homeowner-edger.jpg" }) {
      ...FloatingGridImage
    }
    professionalEdgerImage: file(relativePath: { eq: "stihl/edgers/professional-edgers.jpg" }) {
      ...FloatingGridImage
    }
    batteryEdgerImage: file(relativePath: { eq: "stihl/edgers/battery-edger.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default EdgersPage
